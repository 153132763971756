import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

    general: {
        // height: '50vh',
        background: '#D3DBE2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '5em',
        paddingBottom: '3em',
    },
    
    container: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2% 0 5% 0',
    },

    title: {
        padding: '4% 0 2% 0',
    },

    contact_data: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2%  0 5% 0'
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    
    left_input: {
        marginBottom: '2%',
    },

});
export default useStyles;