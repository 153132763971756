import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },

    palette: {
        primary: {
            main: '#27BEFF',
            contrastText: '#2A323A'
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#2A323A'
        },
    },

    colors: {
        dark_gray: '#2d3748',
        gray: '#646e73',
        blue: '#b8d3e0',
        light_blue: '#d4e4ec',
    },
});

export default theme;