import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        padding: '0.5em',

        [theme.breakpoints.up('sm')]: {
            padding: '3em',
        }
    },

    title: {
        paddingTop: '2em',
        paddingBottom: '3em',
        paddingInline: '0.5em',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '5em',
        }
    },

    link_container: {
        paddingTop: '1.5em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    link: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        padding: '2.5em',
        border: '1px solid lightgray',
        borderRadius: '0.5em',
        boxShadow: '0 0.2em 0.4em 0 rgba(0, 0, 0, 0.2), 0 0.3em 1em 0 rgba(0, 0, 0, 0.19)',

        // [theme.breakpoints.up('sm')]: {
        //     padding: '0.2em',
        // }
    },

}));
export default useStyles;