import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Link, Typography, Box, Menu, MenuItem } from '@mui/material';
import { AccountCircle, Instagram, Facebook, WhatsApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import useStyles from './styles';
import logo from '../../assets/logo.png';

import ViewPort from '../../services/ViewPort';


export default function Header() {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();


    const { width } = ViewPort();
    const sm = 600;

    const [animation, setAnimation] = useState(true);

    useEffect(() => {
        // console.log('useefect -----------------:', animation)
        if (animation) {
            setTimeout(() => {
                // console.log('vou DESLIGAR animação')
                setAnimation(false)
            },7000); //tempo com animação ligada

        } else {
            setTimeout(() => {
                // console.log('vou LIGAR animação');
                setAnimation(true)
            },53000); //tempo com animação desligada
        }
        
      }, [animation]);
    
    return (
        <AppBar className={classes.appbar} position='sticky'>
            <Toolbar className={classes.toolbar}>
                
                <Link className={classes.frame_logo} href='/' color='inherit'>
                    <img className={classes.logo} src={logo} alt='logo' />
                </Link>

                <Box display='flex' alignItems='center'> 
                    {/* <Link href='https://larbor-prod-300817.rj.r.appspot.com/login' color='inherit'>
                        <Typography
                                component='p'
                                variant='body2'
                                color='secondary'
                                >
                            <i>LarborConecta</i>
                        </Typography>
                    </Link> */}

                    <Link href='https://www.instagram.com/larbor.eco/' target='_blank' className={classes.icon} color='inherit'>
                        <Instagram />
                    </Link>
                    <Link href='https://www.facebook.com/larbor.eco' target='_blank' className={classes.icon} color='inherit'>
                        <Facebook />
                    </Link>

                    <Link
                            href={'https://api.whatsapp.com/send?phone=5511930932612&text=Olá, gostaria de mais informações.'}
                            target='_blank'
                            underline='none'
                            className={classes.icon}
                    >
                        <Box className={`${classes.whatsapp_frame} ${animation? classes.animation_on:''}`}>
                            <WhatsApp sx={{ color: '#25d366' }} fontSize={width>sm? 'medium':'large'} />
                            <Typography
                                    component='p'
                                    variant='subtitle2'
                                    sx={{ fontWeight: 'bold' }}
                                    className={classes.whatsapp_txt}
                                    color={theme.colors.dark_gray}
                            >
                                WhatsApp
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            


                
            </Toolbar>
        </AppBar>
    );

}