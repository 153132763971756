import { makeStyles } from '@mui/styles';

import testimonial_small from '../../../assets/testimonial_small.webp';
import testimonial_medium from '../../../assets/testimonial_medium.webp';
import testimonial_large from '../../../assets/testimonial_large.webp';

const useStyles = makeStyles(theme => ({

    // general: {
    //     // backgroundImage: 'linear-gradient(rgba(42, 50, 58, 0), rgba(42, 50, 58, 0.1))',
    //     // backgroundColor: theme.palette.secondary.main,
    //     // height: '85vh',
    //     //minHeight: '633px',
    //     // display: 'flex',
    //     // justifyContent: 'center',
    //     // alignItems: 'center',
    // },

    title: {
        paddingTop: '3em',
        paddingBottom: '0',
        paddingInline: '0.2em',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '5em',
        },
    },

    content: {
        height: 'auto',
        minHeight: '85vh',
        backgroundImage: `url(${testimonial_small})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',

        marginTop: '3em',
        paddingTop: '3em',
        paddingBottom: '3em',

        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${testimonial_medium})`,

            [theme.breakpoints.up('md')]: {
                backgroundImage: `url(${testimonial_large})`,
            },
        },
    },

    testimonial: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '200px',
        maxWidth: '300px',
        marginInline: '50px',
        paddingTop: '1em',
        paddingBottom: '1em',
    },
    

}));
export default useStyles;