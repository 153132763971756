import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

    button: {
        fontWeight: 'bold',
    },

  
});
export default useStyles;