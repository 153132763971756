import React, { useState } from 'react';
import { useTheme, Typography, Box, Card, CardMedia, Slide, Zoom } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Waypoint } from 'react-waypoint';
import ReactPlayer from 'react-player';

import useStyles from './styles';
import ButtonL from '../../../components/ButtonL';
import ViewPort from '../../../services/ViewPort';
import larbor_conecta from '../../../assets/larbor_conecta.webp';

export default function OnlineBanner(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const [load, setLoad] = useState(false);

    return (
        <Box className={classes.general}>
            
            <Box className={classes.title}>
                <Waypoint
                        onEnter={() => setLoad(true)}
                        // onLeave={() => setLoad(false)}
                />
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '200ms' : '0ms' }}>
                    <Typography
                        variant='h6'
                        component='h3'
                        align='center'
                        color='primary'
                        sx={{ fontWeight: 'bold', mb: '0.2em'}}
                        // gutterBottom
                    >
                        PISCINEIRO ONLINE
                    </Typography>
                </Zoom>
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '500ms' : '0ms' }}>
                    <Typography
                        variant='h4'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontWeight: 'bold', mb: '0.5em' }}
                        // gutterBottom
                    >
                        Sua piscina sob nosso cuidado 24hrs/dia para sua tranquilidade
                    </Typography>
                </Zoom>
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '800ms' : '0ms' }}>
                    <Typography
                        variant='h6'
                        component='p'
                        align='center'
                        color={theme.colors.gray}
                        // sx={{ mb: '1em' }}
                        // gutterBottom
                    >
                        <p>Água transparente e sempre pronta para a utilização com os <b>produtos</b> certos na <b>quantidade</b> certa no <b>momento</b> certo.</p> 
                    </Typography>
                </Zoom>
            </Box>
            

            <Box className={classes.content} >
                <Box className={classes.summary} order={2}>
                    <Typography
                        variant='h5'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ lineHeight: '1.8em' }}
                        // sx={{ fontWeight: 'bold' }}
                        gutterBottom
                    >
                        <p>Com o Piscineiro Online estamos <b className={classes.highlight}>MONITORANDO </b>
                        sua piscina 24hrs por dia, fazendo a <b className={classes.highlight}>DOSAGEM </b>
                        de produtos para tratamento da água e <b className={classes.highlight}>INSTRUINDO </b>
                        o zelador na aspiração e remoção das sujeiras maiores.</p>
                    </Typography>
                    
                    <ButtonL 
                            variant='contained'
                            color='primary'
                            endIcon={<ArrowForwardIos />}
                            href='https://api.whatsapp.com/send?phone=5511930932612&text=Gostaria de fazer um orçamento do piscineiro online.'
                            target='_blank'
                    >
                        Pedir Orçamento
                    </ButtonL>
                </Box>

                {/* <Box className={classes.image} order={width>sm? 3:1}>
                    <Card raised>
                        <CardMedia
                            component='img'
                            // height='300px'
                            image={larbor_conecta}
                            alt='larbor_conecta'
                            sx={{ maxWidth: '200px', height: 'auto'}}
                        />               
                    </Card>
                </Box> */}

                <Box className={classes.video_vert} order={width>sm? 3:1}>
                    <ReactPlayer
                        // url='https://www.youtube.com/watch?v=3RB-lG76qyo'
                        // url='https://youtu.be/xZ7UzjaWu3s'
                        url='https://www.youtube.com/watch?v=V1zAr6vMF-Q'
                        controls
                        // light
                        width='100%'
                        height='100%'
                    />
                </Box>
                

            </Box>

        </Box>
    );

}