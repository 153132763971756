import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

import bannerImg_small from '../../../assets/banner_small.webp';
import bannerImg_medium from '../../../assets/banner_medium.webp';
import bannerImg_large from '../../../assets/banner_large.webp';

const useStyles = makeStyles(theme => ({

    banner: {
        minHeight: '85vh',
        backgroundImage: `url(${bannerImg_small})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '12vh',

        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${bannerImg_medium})`,

            [theme.breakpoints.up('md')]: {
                backgroundImage: `url(${bannerImg_large})`,
                
            },
        },

    },

    container: {
        
        width: '100%',
        padding: '0 2em 0 2em',

        [theme.breakpoints.up('sm')]: {
            width: '75%',
            padding: '0 5em 0 10em',

            [theme.breakpoints.up('xl')]: {
                // width: '100%',
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'center',
                // alignItems: 'center',
                padding: '0 5em 0 20em',
            },
        },
    },

    highlight: {
        display: 'flex',
        justifyContent:' center',
        backgroundColor: alpha(theme.colors.blue, 0.15),
        color: theme.palette.primary.contrastText,
        borderRadius: '0.5em',
        width: 'min-content', 
        padding: '0.5em',
        marginBottom: '1em',
        marginLeft: '-0.5em',

        [theme.breakpoints.up('sm')]: {
            width: 'max-content',
        },
    },

    divider: {
        backgroundColor: 'white',
        width: '5em',
        height: '0.2em',
    },

    button: {
        marginTop: '1.3rem',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },

    initial_info_bg: {
        backgroundImage: 'linear-gradient(rgba(42, 50, 58, 0.1), rgba(42, 50, 58, 0), rgba(42, 50, 58, 0))',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
    },

    initial_info: {
        height: 'auto',
        minHeight: '40vh',
        width: '85vw',
        maxWidth: '1300px',
        borderRadius: '10px 10px',
        backgroundColor: 'white',
        position: 'relative',
        top: '-10vh',
        paddingInline: '0',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingTop: '1em',
            paddingInline: '1.5em',
        },

        // '&>div': {   
        //     margin: '4vh 5vw',

        //     [theme.breakpoints.up('sm')]: {
        //         display: 'flex',
        //         justifyContent: 'space-around',
        //     },
        // }
    },

}));
export default useStyles;