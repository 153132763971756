import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Importando páginas
import Home from './pages/Home'; 

export default function RoutesL() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Home/>} />
            </Routes>
        </BrowserRouter>
    );
}   