import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import RoutesL from './routesL';
import theme from './styles/Themes'

function App() {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RoutesL />
        </ThemeProvider>
    );
}

export default App;
