import React from 'react';
import { Typography, Grid, Box } from '@mui/material';

import useStyles from './styles';

import logo from '../../../assets/logo.png';

export default function About() {
    const classes = useStyles();

    return (
        <Box className={classes.general}>
            <Box className={classes.container} marginX={3} marginY={10}>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <Typography color='primary' variant='body1' component='h1'>SOBRE NÓS</Typography>
                        <img className={classes.logo} src={logo} alt="LARBOR" />
                        <Typography className={classes.main_text} color='secondary' variant='h5' component='p'>
                            Cuidamos da sua saúde e do meio ambiente, levando tranquilidade para os banhistas e tratadores de piscinas.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box display={{ xs:'none', sm:'block' }}>
                            <Typography className={classes.text1} color='secondary' component='p'>
                                A Larbor traz para a sua piscina as tecnologias de tratamento de água mais utilizadas em diversos seguimentos no Brasil e no mundo.
                            </Typography>
                            <Typography color='secondary' component='p'>
                                Nosso compromisso com o meio ambiente mantém nosso time inspirado buscando levar tecnologia e praticidade para o tratamento de água do seu lar.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

}