import React, { useState, Fragment } from 'react';
import { Typography, Grid, Box, Zoom } from '@mui/material';
import { useTheme } from '@mui/styles';
import useStyles from './styles';
import { Waypoint } from 'react-waypoint';


export default function Testimonials(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [load, setLoad] = useState(false);

    const testimonials_arr = [
        {
            testimonial: 'Confio no trabalho. Fico feliz pelo ótimo serviço na piscina em que trabalho.',
            author: 'ADRIANO',
            place: 'Jaguaré'
        },
        {
            testimonial: 'Meu trabalho ficou muito mais fácil. Não preciso descobrir como tratar a piscina.',
            author: 'PAULO',
            place: 'Perdizes'
        },
        {
            testimonial: 'Esses meninos são parceiros e sempre me ajudam.',
            author: 'JOSIAS',
            place: 'Paraíso'
        },
        {
            testimonial: 'A piscina fica sempre limpa agora e eles ainda entregam outros produtos, se eu precisar.',
            author: 'ROBERTO',
            place: 'Brooklin'
        },
        {
            testimonial: 'Eu nunca vi tanta tecnologia pra tratar piscina.',
            author: 'RENATO',
            place: 'Sumaré'
        },
    ];

    return (
        <Box>
            {/* <Waypoint
                    onEnter={() => setLoad(true)}
                    // onLeave={() => setLoad(false)}
            /> */}
            <Box className={classes.title}>
                <Typography
                    variant='h6'
                    component='h3'
                    align='center'
                    color='primary'
                    sx={{ fontWeight: 'bold', mb: '0.2em'}}
                    // gutterBottom
                >
                    DEPOIMENTOS
                </Typography>
                <Typography
                    variant='h4'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold', mb: '0.5em' }}
                    // gutterBottom
                >
                    Veja o que nossos clientes estão dizendo
                </Typography>
                <Typography
                    variant='h6'
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    // sx={{ mb: '1em' }}
                    // gutterBottom
                >
                    <p>Trabalhamos em conjunto com o <b>zelador</b> e nosso sucesso é resultado dessa parceria.</p> 
                </Typography>
            </Box>
            <Box className={classes.content} >
                {testimonials_arr.map((e,i) =>
                        <Fragment key={i}>
                            <Zoom
                                    in={load}
                                    style={{ transitionDelay: i*400+'ms' }}
                            >
                                <Box className={classes.testimonial}>

                                    <Typography
                                        variant='body1'
                                        component='h3'
                                        align='center'
                                        color='secondary'
                                        // sx={{ fontWeight: 'bold', mb: '0.2em'}}
                                        // gutterBottom
                                    >
                                        "{e.testimonial}"
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        component='p'
                                        align='center'
                                        color='secondary'
                                        sx={{ fontWeight: 'bold', mt: '0.5em' }}
                                        // gutterBottom
                                    >
                                        {e.author}
                                    </Typography>
                                    <Typography
                                        variant='caption'
                                        component='p'
                                        align='center'
                                        color='secondary'
                                        // sx={{ fontWeight: 'bold', mb: '0.5em' }}
                                        // gutterBottom
                                    >
                                        {e.place}
                                    </Typography>
                                    
                                                
                                </Box>
                            </Zoom>
                            <Waypoint
                                onEnter={() => setLoad(true)}
                            />
                        </Fragment>
                    
                )}
            </Box>
                         
        </Box>
    );

}