import { makeStyles } from '@mui/styles';
import { keyframes } from '@mui/system';

import bannerImg_small from '../../assets/banner_small.webp';
import bannerImg_medium from '../../assets/banner_medium.webp';
import bannerImg_large from '../../assets/banner_large.webp';


const useStyles = makeStyles(theme => ({

    '@keyframes blinker': {
        from: { opacity: 0.3 },
        to: { opacity: 1 },
    },

    appbar: {
        // height: '50px',
        //backgroundColor: '#ffffff0d',
        backgroundImage: `url(${bannerImg_small})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${bannerImg_medium})`,

            [theme.breakpoints.up('md')]: {
                backgroundImage: `url(${bannerImg_large})`,
            },
        },
    },

    toolbar: {
        height: '50px',
        minHeight: 'auto!important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 0 0.5em',
        color: 'white',

        [theme.breakpoints.up('sm')]: {
            margin: '0 1em 0 3em',
        }
    },

    frame_logo: {
        display: 'flex',
        alignItems: 'center',
    },

    logo: {
        width: '130px',
    },

    icon: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.05em',

        [theme.breakpoints.up('sm')]: {
            padding: '0.2em',
        }
    },

    whatsapp_frame: {
        backgroundColor: theme.palette.secondary.main,
        width: 'auto',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1px',
        borderRadius: '12px',
        marginLeft: '4px',
        
        [theme.breakpoints.up('sm')]: {
            padding: '3px 7px 3px 5px',
            borderRadius: '8px',
        }
    },

    whatsapp_txt: {
        // fontWeight: 'bold',
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            display: 'block',
        }
    },

    animation_on: {
        animationName: '$blinker',
        animationDuration: '0.8s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-out',
    },

}));
export default useStyles;