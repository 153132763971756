import React from 'react';
import { Typography, Grid, Box, TextField } from '@mui/material';
import { useTheme } from'@mui/styles';
import { Waypoint } from 'react-waypoint';
import { WhatsApp } from '@mui/icons-material';

import useStyles from './styles';

import ButtonL from '../../../components/ButtonL';


export default function Contact() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box className={classes.general}>
            <Box className={classes.container}>

                <Typography
                    variant='h4'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold', mt: '0.5em' }}
                    // gutterBottom
                >
                    Quer saber mais sobre os serviços da Larbor?
                </Typography>
                <Typography
                    variant='h6'
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    // sx={{ mb: '1em' }}
                    // gutterBottom
                >
                    <p style={{ marginTop: 0, marginBottom: '2em' }}>Entre em contato pelo nosso whatsapp.</p> 
                </Typography>
                
                <ButtonL
                        variant='contained'
                        color='secondary'
                        size='large'
                        startIcon={<WhatsApp sx={{ color: '#25d366' }} />}
                        href='https://api.whatsapp.com/send?phone=5511930932612&text=Olá, gostaria de mais informações sobre os serviços da Larbor.'
                        target='_blank'
                >
                    <b>(11) 93093-2612</b>
                </ButtonL>

            </Box>
        </Box>
    );

}