import React, { useState, Fragment } from 'react';
import { useTheme, Typography, Box, Slide, Divider, Zoom, Link } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import { ArrowForwardIos, ArrowCircleDownRounded } from '@mui/icons-material';
import useStyles from './styles';

import {
            RecordVoiceOverRounded, 
            ThumbDownRounded,
            PsychologyRounded,
            LocalLibraryRounded,
            DangerousRounded,
            FormatColorFillRounded,
            PersonRounded,
            DevicesRounded
        } from '@mui/icons-material';


import ButtonL from '../../../components/ButtonL';
import Item from './Item';
import ViewPort from '../../../services/ViewPort';


export default function Banner(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const [loadButton, setLoadButton] = useState(false);
    const [loadItems, setLoadItems] = useState(false);


    return (  
        <Fragment>
            {/* Banner */}
            <Box className={classes.banner}>
                <Box className={classes.container}>

                    <Waypoint
                        onEnter={() => setLoadButton(true)}
                        // onLeave={() => setLoad(false)}
                    />
                    {/* <Box className={classes.highlight}>
                        <Typography
                                variant='h5'
                                component='h1'
                                color='secondary'
                                // align='left'
                                style={{ fontWeight: 'bold' }}
                        >
                            PISCINEIRO ONLINE
                        </Typography>
                    </Box> */}
                    <Typography
                            variant={ width>sm? 'h3' : 'h4'}
                            component='h2'
                            style={{ color: 'white', marginBottom: '0.5em'  }}
                            // align='left'
                    >
                        <p style={{ margin: 0 }}>Tratamento completo para a <b>piscina</b> do seu condomínio</p>
                        {/* Você não precisa mais se preocupar com o tratamento da piscina do seu condomínio */}
                    </Typography>
                    <Divider className={classes.divider} />
                    
                    <Zoom in={loadButton} timeout={800}>
                        <Box className={classes.button}>
                            <ButtonL 
                                    variant='outlined'
                                    color='secondary'
                                    style={{ margin: '5px' }}
                                    startIcon={<DevicesRounded />}
                                    onClick={props.scrollToOnline}
                                    // href='https://api.whatsapp.com/send?phone=5511930932612&text=Quero saber mais sobre o piscineiro online.'
                                    // target='_blank'
                            >
                                Sem piscineiro
                            </ButtonL>
                            <ButtonL 
                                    variant='outlined'
                                    color='secondary'
                                    style={{ margin: '5px' }}
                                    startIcon={<PersonRounded />}
                                    onClick={props.scrollToClassic}
                                    // href='https://api.whatsapp.com/send?phone=5511930932612&text=Quero saber mais sobre o piscineiro online.'
                                    // target='_blank'
                            >
                                Com piscineiro
                            </ButtonL>
                        </Box>
                    </Zoom>
                       
                    {/* <Link
                            underline='hover'
                            component='button'
                            variant='body2'
                            onClick={props.scrollToDetails}
                            color='secondary'
                            sx={{
                                    fontStyle: 'italic',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: '1em',
                                }}
                    >
                        <ArrowForwardIos sx={{
                                            fontSize: 16,
                                            borderRadius: '8px',
                                            border: '1px solid white',
                                            padding: '2px',
                                            marginRight: '0.2em',
                                        }}
                        />
                        Como o Piscineiro Online funciona
                    </Link> */}
                    
                </Box>
            </Box>

            {/* Informações Iniciais */}
            <Box className={classes.initial_info_bg}>
                <Slide direction='up' in={loadItems} timeout={500} style={{ transitionDelay: loadItems ? '500ms' : '0ms' }}>
                    <Box className={classes.initial_info}>
                        <Item
                            title='Reclamações'
                            description='Condôminos estão sempre reclamando da piscina verde?'
                            Icon={RecordVoiceOverRounded}
                        />
                        <Item
                            title='Inexperiência'
                            description='Zelador sem experiência no tratamento da piscina?'
                            Icon={LocalLibraryRounded}
                        />
                        <Item
                            title='Desperdício'
                            description='Dosagem excessiva de produtos químicos e imprevisibilidade de gastos?'
                            Icon={FormatColorFillRounded}
                        />
                    </Box>
                </Slide>
                <Waypoint
                    onEnter={() => setLoadItems(true)}
                    // onLeave={() => setLoad(false)}
                />
            </Box>

        </Fragment>
    );

}