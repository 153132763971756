import React, { useState, Fragment } from 'react';
import { useTheme, Typography, Box, Container, Divider, Zoom, Avatar } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import { ArrowForwardIos } from '@mui/icons-material';

import useStyles from './styles';


export default function Item(props) {
    const classes = useStyles();
    const theme = useTheme();
    
    // const [loadButton, setLoadButton] = useState(false);

    const { title, description, Icon } = props;

    return (  
        <Box className={classes.general}>
            <Avatar sx={{ bgcolor: theme.colors.light_blue, width: 50, height: 50, mb: '1em' }}>
                <Icon color='primary' />
            </Avatar>
            <Typography
                variant='h6'
                component='h3'
                align='center'
                color={theme.colors.dark_gray}
                sx={{ fontWeight: 'bold' }}
                gutterBottom
            >
                {title}
            </Typography>
            <Typography
                variant='body1'
                component='p'
                align='center'
                color={theme.colors.gray}
                // sx={{ fontWeight: 'bold' }}
                gutterBottom
            >
                {description}
            </Typography>
        </Box>
    );

}