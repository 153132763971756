import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        // height: '85vh',
        //maxHeight: '633px',
        // display: 'flex',
        // alignItems: 'center',
        padding: '2.5em 1em 1em 1em',
        background: 'radial-gradient(100% 236.63% at 0% 0%, rgba(238, 251, 255, 0.75) 0%, rgba(183, 238, 255, 0.75) 100%)',
        // background: `linear-gradient(rgba(183, 238, 255, 0.75), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75))`,
        
        [theme.breakpoints.up('sm')]: {
            padding: '5em 3em 3em 3em',
        },
    },

    title: {
        paddingTop: '1em',
        paddingBottom: '2em',
        paddingInline: '0',

        [theme.breakpoints.up('sm')]: {
            paddingInline: '5em',
            paddingBottom: '0',
        },
    },

    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',

            [theme.breakpoints.up('xl')]: {
                paddingInline: '20em',
            },
        },
    },

    summary: {
        width: '100%',
        padding: '1em 0 3em 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
        [theme.breakpoints.up('sm')]: {
            width: '60%',
            padding: '2em',
        },
    },
    
    image: {
        // height: '100%',
        //width: '100%',
        padding: '0',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            width: '40%',
            padding: '3em',
        },
    },

    video: {
        // width: '500px',
        height: '250px',
        // padding: '2em',
        boxShadow: '0 0.2em 0.4em 0 rgba(0, 0, 0, 0.2), 0 0.3em 1em 0 rgba(0, 0, 0, 0.19)',

        [theme.breakpoints.up('sm')]: {
            width: '500px',
            height: '350px',
        },
    },

    video_vert: {
        // width: '500px',
        height: '75vh',
        // padding: '2em',
        boxShadow: '0 0.2em 0.4em 0 rgba(0, 0, 0, 0.2), 0 0.3em 1em 0 rgba(0, 0, 0, 0.19)',

        [theme.breakpoints.up('sm')]: {
            // width: '500px',
            height: '450px',
        },
    },

    highlight: {
        color: theme.palette.primary.main,
    }

}));
export default useStyles;