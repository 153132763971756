import React, { useState } from 'react';
import { useTheme, Typography, Box, Card, CardMedia, Slide, Zoom } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Waypoint } from 'react-waypoint';

import useStyles from './styles';
import ButtonL from '../../../components/ButtonL';
import ViewPort from '../../../services/ViewPort';
import piscineiro_bruno_large from '../../../assets/piscineiro_bruno_large.webp';
import piscineiro_bruno_small from '../../../assets/piscineiro_bruno_small.webp';

export default function ClassicBanner(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const [load, setLoad] = useState(false);

    return (
        <Box className={classes.general}>
            
            <Box className={classes.title}>
                <Waypoint
                        onEnter={() => setLoad(true)}
                        // onLeave={() => setLoad(false)}
                />
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '200ms' : '0ms' }}>
                    <Typography
                        variant='h6'
                        component='h3'
                        align='center'
                        color='primary'
                        sx={{ fontWeight: 'bold', mb: '0.2em'}}
                        // gutterBottom
                    >
                        PISCINEIRO CLÁSSICO
                    </Typography>
                </Zoom>
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '500ms' : '0ms' }}>
                    <Typography
                        variant='h4'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontWeight: 'bold', mb: '0.5em' }}
                        // gutterBottom
                    >
                        Profissionais especializados cuidando da piscina do seu condomínio
                    </Typography>
                </Zoom>
                <Zoom in={load} timeout={600} style={{ transitionDelay: load ? '800ms' : '0ms' }}>
                    <Typography
                        variant='h6'
                        component='p'
                        align='center'
                        color={theme.colors.gray}
                        // sx={{ mb: '1em' }}
                        // gutterBottom
                    >
                        <p>
                            Oferecemos <b>serviço de piscineiro</b> e entrega de
                            todos os <b>produtos</b> necessários para o tratamento
                        </p> 
                    </Typography>
                </Zoom>
            </Box>
            

            <Box className={classes.content} >
                
                <Box className={classes.image}>
                    <Card raised>
                        <CardMedia
                            component='img'
                            // height='300px'
                            image={width>sm? piscineiro_bruno_large : piscineiro_bruno_small}
                            alt='piscineiro_classico'
                            sx={{ maxWidth: '600px', height: 'auto'}}
                        />               
                    </Card>
                </Box>

                <Typography
                        variant='h6'
                        component='p'
                        align='center'
                        color={theme.colors.gray}
                        // sx={{ mb: '1em' }}
                        // gutterBottom
                    >
                    <p>
                        <b>Monitoramento e correção dos parâmetros</b> da sua piscina, aspiração do fundo e <b>limpeza</b> das paredes e bordas,
                        além da entrega de todos os <b>produtos</b> necessários.
                    </p> 
                </Typography>

                <ButtonL 
                        variant='contained'
                        color='primary'
                        endIcon={<ArrowForwardIos />}
                        href='https://api.whatsapp.com/send?phone=5511930932612&text=Gostaria de fazer um orçamento de piscineiro.'
                        target='_blank'
                >
                    Pedir Orçamento
                </ButtonL>
                

            </Box>

        </Box>
    );

}