import React from 'react';
import { Button } from '@mui/material';

import useStyles from './styles';

export default function ButtonL(props) {
    const classes = useStyles();
    
    return (  
        <Button {...props} className={classes.button}>
            {props.children}
        </Button> 
    );

}