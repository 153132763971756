import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2em 0.5em 0.5em 0.5em',
        width: '90%',
        
        [theme.breakpoints.up('sm')]: {
            width: '250px',
        },
    },

    
}));
export default useStyles;