import React from 'react';

import useStyles from './styles';
import logo from '../../assets/logo.png';


export default function Footer() {
    const classes = useStyles();
    
    return (
        <footer className={classes.general}>
            <img className={classes.logo} src={logo} alt='LARBOR' />
        </footer>
    );

}