import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

    general: {
        background: '#2A323A',
        display: 'flex',
        justifyContent: 'center',
    },
    
    container: {
        maxWidth: '1300px',
        // padding: '5% 3% 8% 3%',

        '&>div': {
            display: 'flex',
            justifyContent: 'space-around',
        },
    },
    
    logo: {
        width: '130px',
        padding: '1% 0 3% 0',
    },

    main_text: {
        paddingBottom: '7%',
    },

    text1: {
        paddingBottom: '3%',
    },
    
});
export default useStyles;