import React, { useRef, Fragment } from 'react';
import { Link } from '@mui/material';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Whatsapp from '../../components/Whatsapp';
import Banner from './Banner';
import Testimonials from './Testimonials';
import About from './About';
import Contact from './Contact';
import OnlineBanner from './OnlineBanner';
import OnlineDetails from './OnlineDetails';
import ClassicBanner from './ClassicBanner';
import Store from './Store';



export default function Home() {

    //título da página
    document.title = 'Larbor - Home';

    const onlinedetails = useRef();
    const classicdetails = useRef();
    const scrollToOnline = () => {
        onlinedetails.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollToClassic = () => {
        classicdetails.current.scrollIntoView({ behavior: 'smooth' })
    }
    
    return(
        <Fragment>
            <Header />
            <Banner scrollToOnline={scrollToOnline} scrollToClassic={scrollToClassic} />
            <div ref={onlinedetails}><OnlineBanner /></div>
            <OnlineDetails />
            <div ref={classicdetails}><ClassicBanner /></div>
            <Testimonials />
            {/* <Store /> */}
            <About />
            <Contact />
            <Footer />
            {/* <Whatsapp msg='Olá, gostaria de mais informações.'/> */}
        </Fragment>
    );
}
