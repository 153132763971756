import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        padding: '0.5em',

        [theme.breakpoints.up('sm')]: {
            padding: '3em',
        }
    },

    title: {
        paddingTop: '2em',
        paddingInline: '0',

        [theme.breakpoints.up('sm')]: {
            paddingTop: '3em',
            paddingInline: '5em',
        }
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        flexDirection: 'column',
        flexWrap: 'wrap',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        }
    },

    card: {
        width: '80vw',
        height: 'auto',
        margin: '1em',

        [theme.breakpoints.up('sm')]: {
            width: '270px',
            // minWidth: '270px',
            height: '330px',
        }
    },

    card_content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },

    icon: {
        // marginTop: '1em',
        padding: '0.2em',
        backgroundColor: theme.palette.primary.main,
        // backgroundColor: theme.colors.blue,
        color: theme.palette.secondary.main,
        borderRadius: '0.2em',
        // alignSelf: 'start',
    },

    icon_rotate: {
        transform: 'rotate(90deg)',

        [theme.breakpoints.up('sm')]: {
            transform: 'rotate(0deg)',
        }
    },

}));
export default useStyles;