import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({


    general: {
        //margin: '2px',
        position: 'fixed',
        // width: '40px',
        // height: '40px',
        // backgroundColor: '#25d366', 
        bottom: '15px',
        right: '15px',
        

        // padding: '5px',
        // borderRadius: '40px',
        // textAlign: 'center',
        //fontSize: '20px',
        // boxShadow: '1px 1px 1px #999',
        zIndex: '999',

        // [theme.breakpoints.up('sm')]: {
        //     width: '50px',
        //     height: '50px',
        //     bottom: '35px',
        //     right: '35px',
        // }
    },
  
}));
export default useStyles;