import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

    general: {
        backgroundColor: '#2A323A',
        padding: '1vh 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
    },
    
    logo: {
        width: '130px',
    }

});
export default useStyles;