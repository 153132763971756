import React, { useState } from 'react';
import { useTheme, Typography, Box, Card, CardContent, Zoom } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { Waypoint } from 'react-waypoint';

import { ArrowForwardRounded, PublishedWithChangesRounded, CircleRounded }from '@mui/icons-material';

import useStyles from './styles';
import ButtonL from '../../../components/ButtonL';


export default function OnlineDetails(props) {
    const classes = useStyles();
    const theme = useTheme();
    
    const [load, setLoad] = useState(false);

    return (
        <Box className={classes.general}>

            <Box className={classes.title}>
                <Typography
                    variant='h4'
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    Como funciona o PISCINEIRO ONLINE?
                </Typography>
                <Typography
                    variant='h6'
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    // sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    <p>Para aproveitar esse serviço é muito <b>simples</b>. São apenas <b>3 etapas</b>:</p> 
                </Typography>
            </Box>

            <Waypoint
                    onEnter={() => setLoad(true)}
                    // onLeave={() => setLoad(false)}
            />

            <Box className={classes.container} >
                
                <Zoom in={load} timeout={800} style={{ transitionDelay: load ? '200ms' : '0ms' }} >
                    <Card raised className={classes.card}>
                        <CardContent className={classes.card_content} >
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.dark_gray}
                                sx={{ fontWeight: 'bold' }}
                                gutterBottom
                            >
                                <p>1. Instalação</p>
                            </Typography>
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.gray}
                                sx={{ width: '100%', flexGrow: 1 }}
                                gutterBottom
                            >
                                <p style={{ margin: 0}}>Primeiro vamos instalar nosso equipamento, chamado <b>LarborConecta</b>, na casa de máquina da sua piscina entre o filtro e a piscina.</p>
                            </Typography>
                            <ArrowForwardRounded fontSize='large' className={`${classes.icon} ${classes.icon_rotate}`} />
                        </CardContent>
                    </Card>
                </Zoom>

                <Zoom in={load} timeout={800} style={{ transitionDelay: load ? '700ms' : '0ms' }}>
                    <Card raised className={classes.card}>
                        <CardContent className={classes.card_content} >
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.dark_gray}
                                sx={{ fontWeight: 'bold' }}
                                gutterBottom
                            >
                                <p>2. Configuração</p>
                            </Typography>
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.gray}
                                sx={{ width: '100%', flexGrow: 1 }}
                                gutterBottom
                            >
                                <p style={{ margin: 0}}>Em seguida, calibraremos os <b>sensores e dosadores</b> e faremos uma <b>avaliação</b> da piscina para definir o tratamento ideal.</p>
                            </Typography>
                            <ArrowForwardRounded fontSize='large' className={`${classes.icon} ${classes.icon_rotate}`} />
                        </CardContent>
                    </Card>
                </Zoom>

                <Zoom in={load} timeout={800} style={{ transitionDelay: load ? '1200ms' : '0ms' }}>
                    <Card raised className={classes.card}>
                        <CardContent className={classes.card_content} >
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.dark_gray}
                                sx={{ fontWeight: 'bold' }}
                                gutterBottom
                            >
                                <p>3. Tratamento</p>
                            </Typography>
                            <Typography
                                variant='body1'
                                component='p'
                                align='center'
                                color={theme.colors.gray}
                                sx={{ width: '100%', flexGrow: 1 }}
                                gutterBottom
                            >
                                <Carousel
                                        animation='slide'
                                        interval={8000}
                                        navButtonsAlwaysInvisible={true}
                                        IndicatorIcon={<CircleRounded sx={{ width: '8px', height: '8px' }} />}
                                        indicatorIconButtonProps={{
                                            style: { color: theme.colors.blue }
                                        }}
                                        activeIndicatorIconButtonProps={{
                                            style: { color: theme.colors.gray }
                                        }}
                                        indicatorContainerProps={{
                                            style: {
                                                marginTop: '2em',
                                                // width: '100px',
                                                textAlign: 'center'
                                            }

                                        }}
                                >
                                    {[
                                        <p style={{ margin: 0 }}>
                                            <b>Monitoramos</b> sua piscina constantemente e assim podemos planejar o tratamento sempre de forma preventiva.
                                        </p>,
                                        <p style={{ margin: 0 }}>
                                            Utilizando nossa experiência <b>dosamos</b> o produto mais adequado sem disperdícios.
                                        </p>,
                                        <p style={{ margin: 0 }}>
                                            <b>Direcionamos</b> o zelador ou responsável nas atividades semanais de aspiração, limpeza de bordas e outras.
                                        </p>
                                    ]}
                                </Carousel>
                            </Typography>
                            <PublishedWithChangesRounded fontSize='large' className={classes.icon} />
                        </CardContent>
                    </Card>
                </Zoom>

            </Box>

        </Box>
    );

}